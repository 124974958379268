import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider} from "@chakra-ui/react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Admin from "./Admin";
import {Landing} from "./landing/Landing";
import { TwitchAuth } from './TwitchAuth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={createBrowserRouter([
        {
          path: '/',
          element: <Landing />,
        },
        {
          path: '/admin',
          element: <Admin />,
        },
        {
          path: 'twitch_oauth',
          element: <TwitchAuth />,
        }
      ])}/>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

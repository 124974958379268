import React, {useEffect} from 'react';
import './Admin.css';
import firebase from 'firebase/compat/app';
import initializeApp = firebase.initializeApp;
import {CategoryScale, LinearScale, BarController, Chart, BarElement} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where
} from "firebase/firestore";
import {Bar} from "react-chartjs-2";
import {
  HStack,
  Text,
  VStack,
  Select,
} from "@chakra-ui/react";

Chart.register(CategoryScale, LinearScale, BarController, BarElement, ChartDataLabels);

const firebaseApp = initializeApp({
  projectId: 'avimate-8048c',
  storageBucket: 'avimate-8048c.appspot.com',
  apiKey: 'AIzaSyBL1T04z-s207BBkp_nXnBR37knzpui7AI',
  authDomain: 'avimate-8048c.firebaseapp.com',
  messagingSenderId: '610140154114',
  appId: '1:610140154114:web:62d3d1d85d0136f0693a1b',
  measurementId: 'G-TPQNWS2L4P',
});

const store = getFirestore(firebaseApp);
const tokensUsedForDecisionInputCollection = collection(store, `tokens_used_for_decision_input`);
const tokensUsedForDecisionOutputCollection = collection(store, `tokens_used_for_decision_output`);
const tokensUsedForConversationInputCollection = collection(store, `tokens_used_for_conversation_input`);
const tokensUsedForConversationOutputCollection = collection(store, `tokens_used_for_conversation_output`);
const charactersUsedForTTSCollection = collection(store, `characters_used_for_tts`);
const wordCountCollection = collection(store, `word_count`);

const todayDateTimeAtMidnight = new Date();
todayDateTimeAtMidnight.setHours(0, 0, 0, 0);

const weekAgoDateTimeAtMidnight = new Date();
weekAgoDateTimeAtMidnight.setDate(weekAgoDateTimeAtMidnight.getDate() - 7);
weekAgoDateTimeAtMidnight.setHours(0, 0, 0, 0);

const monthAgoDateTimeAtMidnight = new Date();
monthAgoDateTimeAtMidnight.setMonth(monthAgoDateTimeAtMidnight.getMonth() - 1);
monthAgoDateTimeAtMidnight.setHours(0, 0, 0, 0);

const openaiVAT = 0.17; // %
const paypalStripeCharge = 0.029; // % per charge
const paypalStripeChargeFixed = 0.3; // USD per charge

const decisionTokensInputPrice = 0.5 * (1 + openaiVAT); // 0.5 USD per 1M input tokens
const decisionTokensOutputPrice = 1.5 * (1 + openaiVAT); // 1.5 USD per 1M output tokens
const conversationTokensInputPrice = 5 * (1 + openaiVAT); // 5 USD per 1M input tokens
const conversationTokensOutputPrice = 15 * (1 + openaiVAT); // 15 USD per 1M output tokens
const ttsPrice = 16 * (1 + openaiVAT); // 16 USD per 1M characters
const subscriptionPrice = 10 - paypalStripeChargeFixed - 10 * paypalStripeCharge; // 10 USD per 10000 words
const subscriptionWordsAmount = 10000; // 10000 words

enum TimeFrame {
  LAST_DAY = 'Last Day',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month'
}

export const Admin = () => {
  const [decisionOutputTokens, setDecisionOutputTokens] = React.useState<number>(0);
  const [decisionInputTokens, setDecisionInputTokens] = React.useState<number>(0);
  const [conversationOutputTokens, setConversationOutputTokens] = React.useState<number>(0);
  const [conversationInputTokens, setConversationInputTokens] = React.useState<number>(0);
  const [charactersUsedForTTS, setCharactersUsedForTTS] = React.useState<number>(0);
  const [wordCount, setWordCount] = React.useState<number>(0);
  const [expenses, setExpenses] = React.useState<number>(0);
  const [income, setIncome] = React.useState<number>(0);
  const [unitFlow, setUnitFlow] = React.useState<number>(0); //per subscription
  const [timeFrame, setTimeFrame] = React.useState<TimeFrame>(TimeFrame.LAST_DAY);

  const initializedRef = React.useRef(false);


  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      return;
    }

    const condition = where(
      'sessionId',
      ">",
      timeFrame === TimeFrame.LAST_DAY ? todayDateTimeAtMidnight.getTime() :
        timeFrame === TimeFrame.LAST_WEEK ? weekAgoDateTimeAtMidnight.getTime() :
          monthAgoDateTimeAtMidnight.getTime()
      );
    onSnapshot(query(tokensUsedForDecisionOutputCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().tokensUsed);
      setDecisionOutputTokens(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });

    onSnapshot(query(tokensUsedForDecisionInputCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().tokensUsed);
      setDecisionInputTokens(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });

    onSnapshot(query(tokensUsedForConversationInputCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().tokensUsed);
      setConversationInputTokens(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });

    onSnapshot(query(tokensUsedForConversationOutputCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().tokensUsed);
      setConversationOutputTokens(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });

    onSnapshot(query(charactersUsedForTTSCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().charactersUsed);
      setCharactersUsedForTTS(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });

    onSnapshot(query(wordCountCollection, condition), (snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data().wordCount);
      setWordCount(data.reduce((acc: number, curr: any) => {
        return acc + curr;
      }, 0));
    });
  }, [timeFrame]);

  /** Calculate expenses and income */
  useEffect(() => {
    const million = 1_000_000;
    const llmExpenses = decisionTokensOutputPrice * (decisionOutputTokens / million)
      + decisionTokensInputPrice * (decisionInputTokens / million)
      + conversationTokensOutputPrice * (conversationOutputTokens / million)
      + conversationTokensInputPrice * (conversationInputTokens / million);
    const ttsExpenses = ttsPrice * (charactersUsedForTTS / million);
    const subscriptionIncome = (wordCount / subscriptionWordsAmount) * subscriptionPrice;

    const unitFlow = (subscriptionIncome - (llmExpenses + ttsExpenses)) / wordCount * subscriptionWordsAmount;

    setExpenses(llmExpenses + ttsExpenses);
    setIncome(subscriptionIncome);
    setUnitFlow(unitFlow);

  }, [decisionOutputTokens, decisionInputTokens, conversationOutputTokens, conversationInputTokens, charactersUsedForTTS, wordCount]);


  return (
    <VStack w='100%' h='100%' alignItems="center" justifyContent="center" p="4">
      <HStack w="full">
        <Text fontSize="sm">Time Frame:</Text>
        <Select
          size="sm"
          w="200px"
          onChange={(e) => setTimeFrame(e.target.value as TimeFrame)}
        >
            <option
              value={TimeFrame.LAST_DAY}
            >{TimeFrame.LAST_DAY}</option>
            <option
              value={TimeFrame.LAST_WEEK}
            >{TimeFrame.LAST_WEEK}</option>
            <option
              value={TimeFrame.LAST_MONTH}
            >{TimeFrame.LAST_MONTH}</option>
        </Select>
      </HStack>
      <HStack position="relative" pt="30px" w="100%" h="500px" padding="20px" justifyItems="center" alignItems="center">
        <Bar
          options={{
            responsive: true,
            plugins: {
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: Math.round,
                font: {
                  weight: 'bold'
                }
              },
              legend: {
                display: true,
                position: 'bottom' as const,
              },
              title: {
                display: true,
                text: 'Chart.js Bar Chart',
              },
            },
          }}
          data={{
            labels: ['Decision Input Tokens', 'Decision Output Tokens', 'Conversation Input Tokens', 'Conversation Output Tokens', 'Characters Used For TTS', 'Word Count'],
            datasets: [{
              label: 'Tokens Used',
              data: [decisionInputTokens, decisionOutputTokens, conversationInputTokens, conversationOutputTokens, charactersUsedForTTS, wordCount],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1
            }]
          }}
        />
      </HStack>
      <HStack>
        <div>Expenses: {expenses} USD</div>
        <div>Income: {income} USD</div>
        <div>Unit Flow: {unitFlow} USD</div>
      </HStack>
    </VStack>
  );
}

export default Admin;

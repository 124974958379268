import {Box, HStack, Image, Link, Spacer, Text} from "@chakra-ui/react";
import aviLogoBmp from "../landing/images/logo512-p-500.png";
import React from "react";

function DiscordIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="white">
      <path
        fill="white"
        d="M10.909 0S7.46 0 3.69 2.463c0 0-3.69 6.194-3.69 13.955 0 0 2.166 3.508 7.781 3.582l1.845-1.866c-3.369-.97-4.492-2.91-4.492-2.91 4.412 3.806 15.802 3.657 19.973 0 0 0-1.283 2.09-4.572 2.91L22.219 20C27.834 19.925 30 16.418 30 16.418c0-7.761-3.69-13.955-3.69-13.955C22.54 0 19.091 0 19.091 0l-.401.299c4.251 1.045 6.979 3.06 6.898 3.134C21.177.299 9.947 0 4.492 3.433c0 0 2.005-1.94 6.658-3.134L10.909 0zm-.722 8.955c.702 0 1.375.275 1.872.765a2.592 2.592 0 010 3.694c-.496.49-1.17.765-1.872.765a2.68 2.68 0 01-1.013-.199 2.65 2.65 0 01-.859-.566 2.61 2.61 0 01-.574-.847 2.58 2.58 0 010-1.999 2.61 2.61 0 01.574-.847 2.65 2.65 0 01.859-.566 2.68 2.68 0 011.013-.199zm9.305 0c.702 0 1.375.275 1.872.765a2.592 2.592 0 010 3.694c-.496.49-1.17.765-1.872.765s-1.375-.275-1.872-.765a2.592 2.592 0 010-3.694 2.664 2.664 0 011.872-.765z"
      ></path>
    </svg>
  );
}

function YoutubeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="white">
      <path
        fill="white"
        d="M29.993 7.049a10.528 10.528 0 00-1.24-5.34A4.03 4.03 0 0026.409.416C22.617.089 18.81-.044 15.003.015 11.21-.047 7.416.082 3.637.403a3.99 3.99 0 00-1.99.957C.421 2.433.285 4.269.148 5.82a59.24 59.24 0 000 8.378 11.76 11.76 0 00.409 2.586c.164.653.497 1.257.968 1.758a3.97 3.97 0 002.031 1.009 64.86 64.86 0 008.858.427c4.77.065 8.953 0 13.9-.362a4 4 0 002.085-1.009c.382-.362.667-.805.831-1.293a13.05 13.05 0 00.709-4.396c.054-.724.054-5.094.054-5.87zm-18.07 6.646V5.691l8.068 4.021c-2.262 1.19-5.247 2.534-8.068 3.982z"
      ></path>
    </svg>
  );
}

export const Footer = () => {
  return (
    <Box bgColor="#85878c" w="full">
      <HStack
        maxW="940px"
        ml="auto"
        mr="auto"
        my={2}
        color="white"
        gap={6}
        flexWrap="wrap"
        px={{base: 5, lg: 0}}
        justifyContent="center"
      >
        <HStack gap={6}>
          <Image w="80px" src={aviLogoBmp}/>
          <Text>
            Made with ❤️ to ✈️ and ☕
          </Text>
        </HStack>
        <Spacer display={{base: "none", md: "block"}}/>
        <HStack gap={6}>
          <Link href="https://www.avimate.io/terms-of-service" isExternal={true}>Terms of Service</Link>
          <Link href="https://www.avimate.io/privacy-policy" isExternal={true}>Privacy Policy</Link>
          <Link href="mailto:staff@avimate.io">staff@avimate.io</Link>
        </HStack>
        <HStack gap={6}>
          <Link href="https://www.youtube.com/channel/UC5jW5g7y6R1VY1L6K5y5J6g" isExternal={true} w="40px" h="40px">
            <Box w="40px" h="40px" bgColor="#df0600" borderRadius="100%" pt="10px" pl="5px">
              <YoutubeIcon/>
            </Box>
          </Link>
          <Link href="https://discord.gg/avimate" isExternal={true} w="40px" h="40px">
            <Box w="40px" h="40px" bgColor="#5860e3" borderRadius="100%" pt="9px" pl="5px">
              <DiscordIcon/>
            </Box>
          </Link>
        </HStack>
      </HStack>
    </Box>
  )
}